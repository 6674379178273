import React, { useState } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';
import validation from './loginvalidation';
import axios from 'axios';

function Login() {
  const [values, setValues] = useState({
    email: '',
    password: '',
    role: '',
  });

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setTouched((prev) => ({ ...prev, [event.target.name]: true }));
  };

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleRoleNavigation = (role, username) => {
    switch (role) {
      case 'SuperAdmin':
        navigate('/SuperAdminDashboard', { state: { username } });
        break;
      case 'Supervisor':
        navigate('/SupervisorDashboard');
        break;
      case 'FarmerAgent':
        navigate('/AgentDashboard');
        break;
      case 'agent':
        navigate('/visits');
        break;
      default:
        navigate('/default-dashboard');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors(validation(values, touched));

    if (!errors.email && !errors.password) {
      try {
        const res = await axios.post(
          `https://backend.prolocaledge.com/auth/login`,
          values
        );

        if (res.data) {
          const { role, username } = res.data;
          localStorage.setItem('userRole', role);
          localStorage.setItem('username', username);
          handleRoleNavigation(role, username);
        } else {
          setErrors({ general: 'No record exists' });
        }
      } catch (err) {
        console.error('Error during API call:', err);
        setErrors({ general: 'An error occurred during login' });
      }
    }
  };

  return (
    <div className="login-container">
      <div className="left-side">
        <div className="left-side-overlay">
          <h1 className="organization-name">Pro-Local Edge</h1>
          <p className="tagline">Empowering Local Prosperity</p>
        </div>
      </div>
      <div className="right-side">
        <div className="login-form">
          <form onSubmit={handleSubmit} aria-labelledby="loginForm">
            <h2 id="loginForm">Login</h2>
            {errors.general && <span className="text-danger">{errors.general}</span>}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email:</label>
              <input
                type="email"
                id="email"
                placeholder="Enter Email"
                name="email"
                onChange={handleInput}
                className="form-control rounded-0"
                aria-invalid={errors.email ? 'true' : 'false'}
              />
              {touched.email && errors.email && <span className="text-danger">{errors.email}</span>}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password:</label>
              <div className="input-group">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Enter Password"
                  name="password"
                  onChange={handleInput}
                  className="form-control rounded-0"
                  aria-invalid={errors.password ? 'true' : 'false'}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleTogglePassword}
                  aria-label={showPassword ? 'Hide password' : 'Show password'}>
                  {showPassword ? 'Hide' : 'Show'}
                </button>
                {touched.password && errors.password && <span className="text-danger">{errors.password}</span>}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="role" className="form-label">Role:</label>
              <select
                id="role"
                name="role"
                onChange={handleInput}
                className="form-control rounded-0"
                aria-invalid={errors.role ? 'true' : 'false'}>
                <option value="">Select Role</option>
                <option value="SuperAdmin">Super Admin</option>
                <option value="Supervisor">Supervisor</option>
                <option value="FarmerAgent">Farmer Agent</option>
              </select>
              {touched.role && errors.role && <span className="text-danger">{errors.role}</span>}
            </div>
            <button type="submit" className="btn btn-success w-100 rounded-100">Login</button>
            <p>You agree to our terms and policies.</p>
            {/* Uncomment for signup link */}
            {/* <Link to="/signup" className="btn btn-default border w-100 bg-light rounded-0 text-decoration-none">Create Account</Link> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
