import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsPeopleFill } from 'react-icons/bs';
import Modal from 'react-modal';
import './wakulima.css'; // Import your CSS file

const Wakulima = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [foundFarmer, setFoundFarmer] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newFarmer, setNewFarmer] = useState({
    fullName: '',
    phone: '',
    constituency: 'Alego-usonga', // default constituency
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [farmerList, setFarmerList] = useState([]);

  const constituencyOptions = [
    { value: 'Alego-usonga', text: 'Alego-usonga' },
    { value: 'Gem-yala', text: 'Gem-yala' },
    { value: 'Mumias-west', text: 'Mumias-west' },
    { value: 'Ugunja', text: 'Ugunja' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFarmer((prevFarmer) => ({
      ...prevFarmer,
      [name]: value,
    }));
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSearch = async () => {
    try {
      let response;
      if (!isNaN(searchTerm)) {
        response = await axios.get(`https://backend.prolocaledge.com/api/farmers/nationalId/${searchTerm}`);
      } else {
        response = await axios.get(`https://backend.prolocaledge.com/api/farmers/name/${searchTerm}`);
      }
      const farmer = response.data[0];
      setFoundFarmer(farmer);
    } catch (error) {
      console.error('Error searching for farmer:', error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedRow) {
        await axios.put(`https://backend.prolocaledge.com/api/farmers/${selectedRow.id}`, newFarmer);
        closeModal();
      } else {
        await axios.post('https://backend.prolocaledge.com/api/farmers', newFarmer);
        closeModal();
      }
      const updatedResponse = await axios.get('https://backend.prolocaledge.com/api/farmers');
      setFarmerList(updatedResponse.data);
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  const handleEdit = (farmer) => {
    setSelectedRow(farmer);
    setNewFarmer({
      fullName: farmer.fullName,
      phone: farmer.phone,
      constituency: farmer.constituency,
    });
    openModal();
  };

  const handleDelete = async (farmerId) => {
    try {
      await axios.delete(`https://backend.prolocaledge.com/api/farmers/${farmerId}`);
      const updatedResponse = await axios.get('https://backend.prolocaledge.com/api/farmers');
      setFarmerList(updatedResponse.data);
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  };

  useEffect(() => {
    const fetchFarmerList = async () => {
      try {
        const response = await axios.get('https://backend.prolocaledge.com/api/farmers');
        setFarmerList(response.data);
      } catch (error) {
        console.error('Error fetching farmer list:', error);
      }
    };
    fetchFarmerList();
  }, []);

  return (
    <div className="wakulima-container">
      <h1 style={{ color: 'black' }}>
        <BsPeopleFill className="icon" /> Farmers
      </h1>

      <h2>Farmers</h2>

      <label style={{ color: 'black' }}>
        Enter Farmer ID or Name:
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </label>
      <button onClick={handleSearch}>Search</button>

      <button onClick={openModal}>Add Farmer</button>

      {foundFarmer ? (
        <div>
          <h3>Farmer Details</h3>
          <p>Name: {foundFarmer.fullName}</p>
          <p>Phone Number: {foundFarmer.phone}</p>
          <p>Constituency: {foundFarmer.constituency}</p>
        </div>
      ) : (
        <p>No farmer found with the provided ID or name.</p>
      )}

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Constituency</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {farmerList.map((farmer) => (
            <tr key={farmer.id}>
              <td>{farmer.fullName}</td>
              <td>{farmer.phone}</td>
              <td>{farmer.constituency}</td>
              <td>
                <button onClick={() => handleEdit(farmer)}>Edit</button>
                <button onClick={() => handleDelete(farmer.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Add Farmer Modal"
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <h3>{selectedRow ? 'Edit Farmer' : 'Add New Farmer'}</h3>
        <form onSubmit={onSubmit}>
          <label>Name:</label>
          <input
            type="text"
            name="fullName"
            value={newFarmer.fullName}
            onChange={handleInputChange}
            required
          />

          <label>Phone Number:</label>
          <input
            type="text"
            name="phone"
            value={newFarmer.phone}
            onChange={handleInputChange}
            required
          />

          <label>Constituency:</label>
          <select
            name="constituency"
            value={newFarmer.constituency}
            onChange={handleInputChange}
            required
          >
            {constituencyOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>

          <div className="btn-farmer">
            <button type="button" onClick={closeModal}>
              Close
            </button>
            <button type="submit">
              {selectedRow ? 'Edit Farmer' : 'Add Farmer'}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Wakulima;
