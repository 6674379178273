import React, { useState } from 'react';
import './wakulima.css'; // Import shared styles

const Communicate = () => {
  const [message, setMessage] = useState('');
  const [recipientType, setRecipientType] = useState(''); // Farmers or Agents
  const [sentMessages, setSentMessages] = useState([]); // Store sent messages

  const handleSendMessage = () => {
    if (!message || !recipientType) {
      alert('Please compose a message and select a recipient type.');
      return;
    }

    const newMessage = {
      message: message,
      recipient: recipientType === 'all' ? 'All' : recipientType,
      timestamp: new Date().toLocaleString(),
    };

    setSentMessages([newMessage, ...sentMessages]);
    setMessage(''); // Clear message field
  };

  return (
    <div className='wakulima-container'>
      <h1 style={{ color: 'black' }}>Communication Management</h1>

      {/* Compose SMS Section */}
      <div className='compose-section'>
        <h2>Compose SMS</h2>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder='Write your message here...'
          rows='5'
          style={{ width: '100%', padding: '10px', borderRadius: '5px' }}
        />

        <div style={{ marginTop: '10px' }}>
          <label>Select Recipients: </label>
          <select
            value={recipientType}
            onChange={(e) => setRecipientType(e.target.value)}
            style={{ padding: '5px', marginLeft: '10px' }}
          >
            <option value=''>Select</option>
            <option value='allFarmers'>All Farmers</option>
            <option value='allAgents'>All Agents</option>
            <option value='individualFarmer'>Individual Farmer</option>
          </select>
        </div>

        <button
          onClick={handleSendMessage}
          style={{
            padding: '10px 20px',
            backgroundColor: '#0070c9',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: '20px',
          }}
        >
          Send Message
        </button>
      </div>

      {/* Sent Messages Section */}
      <div className='sent-messages-section'>
        <h2>Sent Messages</h2>
        {sentMessages.length === 0 ? (
          <p>No messages sent yet.</p>
        ) : (
          <ul>
            {sentMessages.map((msg, index) => (
              <li key={index}>
                <strong>To:</strong> {msg.recipient} <br />
                <strong>Message:</strong> {msg.message} <br />
                <small><em>Sent on: {msg.timestamp}</em></small>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Communicate;
